.scroll-border-class {
  position: absolute;
  margin: 0;
  top: calc(100vh - 100px);
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 60px;
  border: 2px solid #f39201;
  border-radius: 40px;
  opacity: 0;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-name: fadeInBorder;
  animation-fill-mode: forwards;
  z-index: 2;
}

.fadeOut {
  opacity: 1;
  animation-name: fadeOutBorder;
  animation-duration: 1s;
  animation-delay: 0s;
}
#scroll-dot {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background-color: #f39201;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: moveDot;
}

@keyframes fadeInBorder {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutBorder {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes moveDot {
  0% {
    opacity: 1;
    top: 10px;
  }
  95% {
    opacity: 0;
    top: 50px;
  }
  100% {
    top: 10px;
    opacity: 0;
  }
}

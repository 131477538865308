@media screen and (max-width: 900px) {
  .dark-closed {
    left: 100vw;
    opacity: 0;
  }
  .dark-open {
    left: 0;
    opacity: 1;
  }
  #dark-menu-bg {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    transition: opacity 500ms;
  }

  .navbar-container-default {
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    background-color: rgba(48, 72, 87, 0);
    transition: background-color 100ms linear;
    display: grid;
    grid-template-columns: 30vw auto max-content;
    grid-template-rows: 60px auto;
    align-items: start;
  }

  .navbar-default {
    background-color: rgba(48, 72, 87, 0);
    grid-column: 3/-1;
    margin-right: -230px;
  }
  .navbar-scrolled {
    background-color: rgba(48, 72, 87, 1);
  }
  .navbar-default ul {
    color: white;
    background-color: rgba(48, 72, 87, 1);
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-end;
    height: 100vh;
    margin-right: 0;
    padding-top: 80px;
    padding-left: 0;
  }

  .navbar-default ul li {
    display: block;
    margin: 1em;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
  .navbar-language-line {
    height: 1px;
    width: 20px;
    margin-left: 1em;
    align-self: flex-start;
  }
  .navbar-logo {
    grid-column: 1/2;
    grid-row: 1/2;
    height: 1.5em;
    grid-row: 1/2;
    align-self: center;
    justify-self: start;
  }

  #navbar-logo {
    height: 90%;
    width: auto;
    margin-left: 1em;
  }

  #menu-button-div {
    display: grid;
    position: absolute;
    top: 5px;
    right: 1em;
    justify-items: center;
  }

  #menu-list {
    transition: margin-right 0.5s;
  }
  .menu-open {
    margin-right: 0px;
  }

  .menu-bar-one {
    position: absolute;
    top: 12px;
  }
  .menu-bar-one-close {
    width: 25px;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    top: 20px;
  }
  .menu-bar-two {
    position: absolute;
    top: 21px;
  }
  .menu-bar-two-close {
    width: 0px;
    top: 18px;
    opacity: 0;
  }
  .menu-bar-three {
    position: absolute;
    top: 30px;
  }
  .menu-bar-three-close {
    width: 25px;
    transform: rotate(-45deg);
    transform-origin: 50% 50%;
    top: 20px;
  }
}

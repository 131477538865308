#FormSpeedMB {
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 40;
  stroke-dasharray: 660;
  stroke-dashoffset: 0;
  transform-origin: 160px 160px;
  transform: rotate(0deg);
}

#speedFillMB {
  fill: none;
  stroke: #869cc6;
  stroke-width: 40;
  stroke-dasharray: 880;
  stroke-dashoffset: 880;
  transform-origin: 160px 160px;
  transform: rotate(0deg);
  transition: all 1s;
  -webkit-transition: all 1.5s ease-out;
}

#FormSpeedFillMB {
  fill: none;
  stroke: #869cc6;
  stroke-width: 40;
  stroke-dasharray: 880;
  stroke-dashoffset: 880;
  transform-origin: 160px 160px;
  transform: rotate(0deg);
  transition: all 1.5s ease-out;
  -webkit-transition: all 1.5s ease-out;
}

#FormNeedleMB {
  transform-origin: 150px 150px;
  transform: rotate(0deg);
  stroke: none;
  fill: #4a5681;
  transition: all 1.5s ease-out;
  -webkit-transition: all 1.5s ease-out;
}

.form-svg-container {
  margin-bottom: -1.5em;
}

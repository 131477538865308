@media screen and (max-width: 900px) {
  .section-one-intro {
    font-size: 8vw;
  }
  .contact-border {
    padding: 2em 2em;
    background-color: rgba(48, 72, 87, 1);
  }

  .contact-section-one {
    background-position: 80% 50%;
  }

  .contact-name {
    font-size: 5vw;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 100%;
  }

  .contact-email {
    font-size: 5vw;
    font-weight: bold;
    color: #fff;
    text-align: center;
    line-height: 100%;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    transition: color 1s ease;
  }
}

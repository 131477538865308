.evaluation-section-one {
  background-color: darkslategrey;
  background-image: url("../images/test-desktop-1920.jpg");
  background-size: cover;
  background-repeat: no-repeat center center fixed;

  background-position: center;
  background-position: 50% 10%;
  height: 100vh;
  margin: 0;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: flex-end;
  animation-name: slimDown;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes slimDown {
  0% {
    height: 80vh;
  }
  100% {
    height: 40vh;
  }
}

.evaluation-section-two {
  display: grid;
  justify-content: center;
  justify-items: center;
}

.evaluation-section-one-intro {
  font-size: 4vw;
  font-weight: 700;
  margin-bottom: 2em;
  color: #fff;
  text-align: center;
  animation-name: moveIntroDown;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-duration: 2s;
}

@keyframes moveIntroDown {
  0% {
    margin-bottom: 2em;
  }
  100% {
    margin-bottom: 0em;
  }
}

.first-word {
  color: #f39201;
}

.evaluation-section-three {
  display: grid;
  grid-template-rows: auto;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
  column-width: 100vw;
}

.section-three-divider {
  background-color: black;
  opacity: 0.1;
  width: 80vw;
  height: 1px;
}
.section-three-button {
  display: grid;
  width: 200px;
  height: 40px;
  margin: 5px;
  border: 2px solid;
  border-color: #f39201;
  color: #f39201;
  border-radius: 20px;
  align-content: center;
  justify-self: center;
  cursor: pointer;
}
.section-three-more-info {
  margin-top: 10px;
  opacity: 0.2;
  color: black;
}

@media screen and (max-width: 900px) {
  .section-one {
    background-image: url("../images/home-mobile-2.jpg");
  }
  .section-one-intro {
    font-size: 8vw;
    margin-bottom: 3em;
  }

  #brainzLogoLink {
    position: absolute;
    right: 0;
    top: calc(100vh - 120px);
  }

  #brainzLogo {
    width: 120px;
    height: 120px;
  }

  #brainz500 {
    display: grid;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 120px;
    height: 120px;
    top: calc(100vh - 120px);
    right: 100px;
  }
  #brainz500Logo {
    width: 80px;
    height: 80px;
  }

  #brainz500c {
    height: auto;
    width: 80vw;
    border: 2px solid white;
  }

  #brainz500:hover {
    z-index: 1;
  }

  #brainz500:hover ~ #brainz500Cert {
    opacity: 0;
    background-color: #00000000;
    z-index: 1;
  }

  /* */
  .section-content-intro-questions-container {
    grid-auto-flow: row;
    grid-template-columns: auto;
  }

  /* */
  .section-content-links-container {
    grid-auto-flow: row;
    grid-template-columns: auto;
    grid-template-rows: 1fr auto 1fr;
  }
  .section-content-link-devider {
    height: 1px;
    width: 55px;
    justify-self: center;
    margin: 0.2em;
  }
  /* */
  .section-two {
    grid-template-columns: 100vw;
    row-gap: 1em;
  }
  .section-two-intro {
    margin: 1.5em 1.5em;
  }
  .title-medium {
    font-size: 10vw;
  }
  .section-two img {
    width: 85vw;
  }
  .image-title {
    font-size: 6vw;
  }
  .service-image-description {
    width: 85vw;
    padding: 1em 0em;
    padding-top: 0.3em;
  }

  .section-three {
    grid-template-columns: 1fr;
    row-gap: 1em;
    padding: 0em 0em;
  }
  .section-three-intro {
    margin: 2em 2vw;
    margin-bottom: 0em;
  }
  .quote {
    max-width: 85vw;
  }

  .title-medium-subtle {
    font-size: 6vw;
  }

  .section-four {
    padding: 1em 5vw;
  }
  .logo-div {
    width: 100px;
    margin: 0.2em 0.2em;
  }
}

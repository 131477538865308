html {
  /* To make use of full height of page*/
  min-height: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif;
  padding: 0;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif;
  color: #404040;
  background-color: white;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.App {
}

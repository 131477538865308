.progress-arrow {
  display: inline-block;
  grid-column: 1/6;
  justify-self: center;
  height: 0px;
  visibility: hidden;
}

.progress-arrow-show {
  visibility: visible;
  height: auto;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  width: 0;
  height: 0;
  border-left: 1.5em solid transparent;
  border-right: 1.5em solid transparent;
}

@-webkit-keyframes move {
  0% {
    transform: translate3d(0px, -1.5em, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes move {
  0% {
    transform: translate3d(0px, 1.5em, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

.form-container {
  background-color: #f5f5f5;
  display: grid;
  justify-content: center;
  align-content: center;
  padding: 2em;
  min-height: 100vh;
}

.form-page {
  width: 900px;
  padding: 1em;
  background-color: #6e7f89;
  border: 1em solid white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  display: grid;
  grid-template-rows: auto;
  color: white;
  justify-items: center;
  grid-template-columns: 100%;
}
.logo-container {
  display: grid;
  justify-content: center;
  align-content: center;
  padding: 1em 0;
}

.logo-container > img {
  width: 200px;
  height: auto;
}

.title-container {
  background-color: #304857;
  width: calc(100% + 2em);
  justify-self: center;
  font-size: 2em;
  padding: 1em 2em;
  text-align: center;
  font-weight: 500;
  font-family: "Ultra", serif;
}

.form-instructions-container {
  display: grid;
  max-width: 80%;
  font-size: 0.9em;
  text-align: center;
  padding: 0.5em 0;
}
.form-field-container {
  display: grid;
  text-align: center;
  width: 80%;
}
.form-field-container form {
  width: 100%;
}

.form-field-container input {
}

.form-text-field-type-one {
  text-align: center;
  width: 80%;
  border-style: none;
  background-color: #304857;
  border: 2px solid #304857;
  color: white;
  font-size: 1.5em;
  font-weight: 700;
  padding: 0.5em 0.5em;
}

.form-text-field-type-two {
  text-align: center;
  width: 80%;
  height: 4em;
  resize: vertical;
  padding: 0.5em;
}
.example-text {
  font-size: 0.8em;
  opacity: 0.8;
  min-height: 0.8em;
}
.form-spacer {
  height: 20px;
}

img#logo-black {
  display: none;
}

.form-print-button {
  height: 50px;
  min-width: 200px;
  background-color: #f39201;
  border-radius: 35px;
  color: white;
  margin: 1em 0.5em;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1em;
}

.form-done-container-top {
  width: 100%;
}
.form-done-container {
  display: grid;
  grid-auto-rows: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-template-columns: 1fr;
  margin-bottom: 10em;
  margin-top: 2em;
  grid-template-columns: 100%;
}
.show-print {
  display: grid;
  grid-template-columns: 100%;
}
.hide-print {
  display: none;
}

.done-container-one {
  grid-row: 1/2;
  color: white;
  font-size: 2em;
  font-weight: 500;
  text-align: center;
  overflow-wrap: break-word;
}
.form-result-one {
  font-size: 3em;
  font-weight: 700;
  margin-top: -0.4em;
  padding: 0.2em;
  padding-bottom: 0;
  display: grid;
  width: 100%;
  overflow-wrap: break-word;
}
.done-container-header {
  background-color: #304857;
  color: white;
  border: 2px solid #304857;
}
.form-done-title {
  opacity: 0.7;
  font-size: 0.8em;
  padding: 0.8em;
  padding-bottom: 5px;
}
.form-daily-example {
  padding: 0.5em;
  font-size: 0.8em;
}

.done-container-two {
  grid-row: 2/3;
  display: grid;
  grid-template-columns: 100%;
}

.done-container-three {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  justify-items: center;
}
.done-three-line {
  width: 4px;
  background-color: #304857;
  height: 15px;
  border: 2px solid #304857;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.done-container-four {
  display: grid;
  grid-template-rows: 2em auto;
  justify-content: center;
  justify-items: center;
  background-color: #304857;
  grid-template-columns: 100%;
  border: 2px solid #304857;
}

.done-container-four-b {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  justify-items: center;
  width: 100%;
}
.done-comp-container {
  background-color: #304857;
  color: white;
  padding: 0.5em;
  width: 100%;
  display: grid;
  grid-template-rows: auto;
}
.done-comp-word {
  font-size: 1.5em;
  font-weight: 700;
  overflow-wrap: break-word;
  max-width: 250px;
  justify-self: center;
}
.done-container-five {
  height: 40px;
}
.done-five-line {
  border-color: #304857;
  border-style: none none dashed none;
  height: 20px;
  margin-bottom: 20px;
}
.form-result-five {
  font-size: 2em;
  font-weight: 700;
  margin-top: -0.4em;
  padding: 0.2em;
  overflow-wrap: break-word;
}

/*  Mobileview */

@media screen and (max-width: 900px) {
  .form-page {
    width: 98vw;
    padding: 0;
  }

  .title-container {
    padding: 1em 0.5em;
    margin-left: -0.5em;
    width: calc(100% + 1em);
    font-size: 1em;
    font-weight: 500;
  }

  .form-result-one {
    font-size: 2em;
  }
  .done-comp-word {
    font-size: 1em;
    overflow-wrap: break-word;
    max-width: 170px;
  }
  .done-container-one {
    font-size: 1.6em;
  }
}

@media screen and (max-width: 550px) {
  .form-field-container {
    width: auto;
  }

  .done-container-four-b {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 400px) {
  .form-result-one {
    font-size: 1em;
  }
  .form-result-five {
    font-size: 1em;
  }
  .done-container-one {
    font-size: 1em;
    overflow-wrap: break-word;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .form-page * {
    visibility: visible;
    color: black;
  }
  .form-page {
    position: absolute;
    left: 0;
    top: 0;
  }
  .title-container {
    font-weight: 500;
    background-color: white;
    -webkit-print-color-adjust: exact;
    color: black;
    padding: 0em !important;
  }

  .form-instructions-container {
    max-width: 95%;
    font-size: 0.8em;
  }
  .title-container {
    padding: 0.2em;
  }
  .form-meter-container {
    box-shadow: none;
  }
  img#logo-white {
    display: none;
  }
  img#logo-black {
    display: inline-block;
  }
  .form-print-button {
    display: none;
  }
  .form-devider {
    background-color: black;
    opacity: 0.1;
    -webkit-print-color-adjust: exact;
  }
}

.loading-circle {
  stroke: #f39201;
  stroke-width: 10;
  fill: none;
  opacity: 0;
}
.loading-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loading-container > svg {
  align-self: flex-end;
}
.loading-container > h3 {
  align-self: flex-start;
  text-align: center;
  margin-top: 0;
  color: #f39201;
  font-weight: 600;
}
#loading-circle-one {
  animation: loading 2s infinite;
}
#loading-circle-two {
  animation: loading 2s infinite;
  animation-delay: 0.3s;
}
#loading-circle-three {
  animation: loading 2s infinite;
  animation-delay: 0.6s;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

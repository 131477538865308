@media screen and (max-width: 900px) {
  .about-section-one {
    background-position: 70% 50%;
  }
  .about-section-two {
    padding: 0.1em 0.1em;
  }
  .about-title-medium {
    font-size: 2.5em;
  }

  .about-section-two-intro {
    padding: 1em;
  }
  .about-book {
    padding: 0 1em;
  }
  br {
    margin-top: 1em;
  }
  .about-section-one-intro {
    font-size: 8vw;
    margin-bottom: 3em;
  }
}

.edit-webform-container {
  padding: 1em 1em;
  display: grid;
  justify-content: center;
  margin-top: 100px;
}

textarea {
  height: 100px;
  width: 200px;
}

.edit-form-question-container {
  display: grid;
  justify-content: center;
  margin: 1em 1em;
  text-align: center;
}
.edit-form-question-container > label {
  font-size: 0.8em;
  font-weight: bolder;
}
#webFormID > button {
  height: 30px;
  margin: 0.5em;
}

#webFormID {
  display: grid;
  justify-content: center;
}

#webFormID > label {
  font-weight: bolder;
}

.services-section-one {
  background-color: darkslategrey;
  background-image: url("../images/service-two.png");
  background-size: cover;
  background-repeat: no-repeat center center fixed;
  background-position: center;
  height: 100vh;
  margin: 0;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: flex-end;
  animation-name: slimDownAbout;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes slimDownAbout {
  0% {
    height: 80vh;
  }
  100% {
    height: 40vh;
  }
}

.services-section-two {
  display: grid;
  grid-auto-flow: row;
  padding: 2em 4em;
  justify-items: center;
  text-align: center;
}

.services-categories-container {
  display: grid;
  grid-auto-flow: column;
  column-gap: 1em;
  margin-bottom: 1em;
}
.services-category-text {
  font-weight: 600;
  cursor: pointer;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.category-active {
  background-color: #f39201;
  border-radius: 50px;
  padding-left: 1em;
  padding-right: 1em;
  color: white;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
}

.services-content-container {
  display: grid;
  grid-template-rows: 170px 185px 30px;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 400px;
  opacity: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  margin: 1em;
  padding: 10px;
  transition: all 0.5s;
  animation-name: contentAppear;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.services-image-container {
  display: grid;
  justify-content: center;
  align-items: center;
  align-self: start;
  overflow: hidden;
  transition: all 0.5s;
  grid-template-columns: 100%;
  grid-template-rows: 170px;
}

.services-images {
  width: 100%;
  height: auto;
  grid-column: 1/-1;
  grid-row: 1/-1;
}
.services-title {
  color: white;
  grid-column: 1/-1;
  grid-row: 1/-1;
  font-weight: 700;
  font-size: 1.2em;
  transition: font-size 0.5s;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.services-info-text {
  grid-row: 2/3;
  grid-column: 1/-1;
  height: 100%;
  font-size: 0.8em;
  align-self: start;
  overflow: hidden;
  position: relative;
}
.services-info-text-fade {
  grid-row: 2/3;
  grid-column: 1/-1;
  z-index: 2;
  align-self: end;
  width: 100%;
  height: 40px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.85),
    rgba(255, 255, 255, 1)
  );
}
.services-read-more-button {
  grid-row: 3/-1;
  font-size: 0.6em;
  color: #f39201;
  border: 1px solid #f39201;
  border-radius: 50px;
  display: grid;
  justify-self: center;
  align-items: center;
  padding: 2em;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  cursor: pointer;
}

.services-read-more-button-active {
  font-size: 0.8em;
}

.button-download-coach {
  background-color: #304857;
  width: 150px;
  height: 30px;
  font-size: 0.8em;
}

.services-ws-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.services-ws-day-container {
  border: 1px solid black;
  padding: 1em;
  margin: 0.2em;
  width: 290px;
  font-size: 0.8em;
}
.services-ws-day-session-container {
  border: 1px solid #f39201;
  width: 90%;
  padding: 0.3em;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.2em;
}
.services-download-button {
  font-size: 0.65em;
  position: relative;
  background-color: #304857;
  left: 50%;
  transform: translateX(-50%);
}
/* INACTIVE
.services-read-more-button:hover {
  color: white;
  background-color: #f39201;
}
*/

/* Active course */

.services-active {
  width: 100%;
  max-width: 1000px;
  height: auto;
  grid-template-rows: 120px auto 30px;
  grid-template-columns: 100%;
}

.image-active {
  width: 100%;
  grid-column: 1/-1;
  grid-row: 1/2;
}

.services-image-container-active {
  grid-template-rows: 120px;
}

.services-title-active {
  font-size: 2em;
}

.services-content-large {
  text-align: left;
  padding: 1em;
  padding-bottom: 0.2em;
  animation-name: scaleLarge;
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  grid-row: 2/3;
  grid-column: 1/-1;
  opacity: 0;
}

.services-contact-button {
  font-size: 1em;
  background-color: #f39201;
  color: white;
  font-weight: 500;
  padding: 2em;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  margin-top: 1em;
  border-radius: 50px;
  width: max-content;
  justify-self: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.services-vbs-title{
  font-weight: 600;
  font-size: 1.8rem;
}
.services-vbs-section-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.services-vbs-section-content{
display: grid;
grid-template-columns: 1fr;
padding: 1rem 2rem;
border: 1px solid black;
margin: 1rem;
justify-content: center;
align-items: center;
font-weight: 600;
}
.services-vbs-section-day{
font-size: 0.8rem;
font-variant-caps: gray;
font-weight: 100;
}
.services-vbs-arrow{
  justify-self: center;
  align-self: center;
}

.services-video-container{
  display: grid;
  justify-content: center;
  margin: 1rem 0rem;
}

@keyframes scaleLarge {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes contentAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  .services-categories-container {
    grid-auto-flow: row;
    column-gap: 1em;
    margin-bottom: 1em;
  }
  .services-section-two {
    padding: 0;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .services-content-container {
    margin: 0;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  .services-content-large {
    padding: 0.1em;
  }
  .services-title-active {
    font-size: 1.2em;
  }
}


.home-container {
  display: grid;
}
.section-one {
  background-color: darkslategrey;
  background-image: url("../images/home-desktop-2-1920.jpg");
  background-size: cover;
  background-repeat: no-repeat center center fixed;
  background-position: 20% 55%;
  height: 100vh;
  margin: 0;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: flex-end;
  animation-name: slimDownHome;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes slimDownHome {
  0% {
    height: 105vh;
  }
  100% {
    height: 100vh;
  }
}

.section-one-intro {
  font-size: 4vw;
  font-weight: 700;
  margin-bottom: 200px;
  color: #fff;
  text-align: center;
  z-index: 2;
}

.first-word {
  color: #f39201;
}

/* SECTION TWO____________________________*/
.section-two {
  display: none;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  text-align: center;
}

.section-services {
  display: grid;
  justify-items: center;
  text-align: center;
}

.section-two-intro {
  grid-column: 1/-1;
  margin: 3em 20vw;
  display: grid;
  justify-items: center;
}

.title-medium {
  font-size: 4vw;
  font-weight: 500;
}

.title-medium-description {
  font-size: 1.2em;
  font-weight: 500;
}
.title-medium-bold {
  font-weight: 600;
}
.title-medium-secondary {
  font-size: 0.8em;
  font-weight: 500;
}
.title-medium-devider {
  height: 1px;
  width: 70%;
  background-color: #f3f3f3;
  margin: 0.5em 0;
}

.service-container {
  display: grid;
  grid-template-rows: max-content;
  justify-items: center;
}
.section-two img {
  height: auto;
  width: 26vw;
  grid-column: 1/-1;
  grid-row: 1/-1;
}

.service-image {
  display: grid;
  justify-items: center;
  cursor: pointer;
}
.image-title {
  grid-column: 1/-1;
  grid-row: 1/-1;
  font-size: 1.5vw;
  font-weight: 800;
  color: white;
  align-self: center;
}

.service-image img:hover ~ .image-title,
.image-title:hover {
  color: #f39201;
}

#brainzLogoLink {
  position: absolute;
  right: 0;
  bottom: 0;
}
#brainzLogo {
  width: 200px;
  height: 200px;
}

#brainz500 {
  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 200px;
  height: 150px;
  bottom: 200px;
  right: 0;
  z-index: 2;
}

.brainz500Logo {
  width: 150px;
  height: 150px;
}
#brainz500Cert {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #00000000;
  z-index: -2;
  transition: all 1s;
  display: grid;
  justify-content: center;
  align-items: center;
}

#brainz500:hover {
  z-index: 12;
}

#brainz500:hover ~ #brainz500Cert {
  opacity: 1;
  background-color: #000000cc;
  z-index: 11;
}

#brainz500c {
  height: 80vh;
  width: auto;
  border: 2px solid white;
}

#brainz500cLink {
  position: absolute;
  width: 100%;
  height: 100%;
}
.service-image-description {
  width: 26vw;
  padding: 0.5em 0em;
  font-weight: 300;
  align-self: start;
}

.service-container-extended {
  grid-column: 1/-1;
  height: 0px;
  opacity: 0;
  transition: height 1s ease, opacity 1s ease;

  text-align: start;
  overflow: hidden;
}
.service-container-extended-expanded {
  height: auto;

  opacity: 1;
}

.section-two-outro {
  grid-column: 1/-1;
  width: 100%;
  display: grid;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
}

.primary-button {
  height: 60px;
  min-width: 300px;
  background-color: #f39201;
  border-radius: 35px;
  color: white;
  margin: 0.2em 0.5em;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}
.primary-button:hover {
  background-color: #c17400;
}
.secondary-button {
  height: 60px;
  min-width: 300px;
  background-color: white;
  border: 2px solid #f39201;
  border-radius: 35px;
  color: #f39201;
  margin: 0.5em 0.5em;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
}
.secondary-button:hover {
  background-color: #eee;
}
.secondary-button-hide {
  display: none;
}

.section-two-extended {
  grid-column: 1/-1;
  display: grid;
  padding: 0em 5em;
}

/* SECTION CONTENT INTRO______________________________________*/
.section-content-intro {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-items: center;
  justify-self: center;
  text-align: center;
  row-gap: 1em;
  padding: 2em;
  width: 100%;
  max-width: 900px;
}

.section-content-intro-questions-container {
  grid-column: 1/-1;
  grid-row: 1/2;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}
.section-content-intro-questions-text {
  grid-column: 1/-1;
  grid-row: 2/-1;
}
.section-content-question-container {
  display: grid;
  grid-template-rows: auto;
  padding: 1em;
  justify-content: center;
  justify-items: center;
}

.section-content-question {
  grid-row: 1/2;
  font-size: 1.5em;
  font-weight: 700;
  /*animation-name: questionAppear;*/
  animation-fill-mode: forwards;
  animation-duration: 1s;
  position: relative;
  transform: translateY(50%);
  opacity: 0;
}
.aniInQuestion {
  animation-name: questionAppear;
}
.delay-one {
  animation-delay: 0s;
}
.delay-two {
  animation-delay: 0.5s;
}
.delay-three {
  animation-delay: 1s;
}
@keyframes questionAppear {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.section-content-question-sub {
  grid-row: 3/4;
  font-size: 0.8em;
  opacity: 0;
  position: relative;
  animation-duration: 1s;
  transform: translateY(-40%);
  animation-fill-mode: forwards;
}
.aniInSub {
  animation-name: subAppear;
}
@keyframes subAppear {
  0% {
    opacity: 0;
    transform: translateY(-40%);
  }
  100% {
    opacity: 0.8;
    transform: translateY(0%);
  }
}

.section-content-question-line {
  width: 0%;
  height: 1px;
  background-color: #f39201;
  margin-bottom: 0.2em;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.aniInLine {
  animation-name: lineAppear;
}
@keyframes lineAppear {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

/* SECTION CONTENT SERVICES______________________________________*/
.section-content-services {
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1.5em;
  position: relative;
}
.services-fade-div {
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 1)
  );
  height: 50%;
  width: 100%;
}
.section-content-services-img-container {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  max-width: 900px;
}
.section-content-service-button {
  display: grid;
  border-radius: 100px;
  background: #f39201;
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  padding: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  align-items: center;
  width: max-content;
  justify-self: center;
  z-index: 2;
  cursor: pointer;
  margin-top: -0.5em;
}
.section-content-service-button:hover {
  background: #df8703;
}

.section-content-service-button-secondary {
  display: grid;
  border-radius: 100px;
  color: #f39201;
  border-color: #f39201;
  border: 1px solid;
  font-weight: 500;
  font-size: 0.8em;
  padding: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  align-items: center;
  width: max-content;
  justify-self: center;
  z-index: 2;
  cursor: pointer;
  margin-top: 0.5rem;
}
.services-img-container {
  position: relative;
  width: 100%;
  height: 50px;
  overflow: hidden;
}
.services-img {
  width: 100%;
  border: 5px solid white;
}
.services-img-title {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 1em;
}

/* SECTION CONTENT ARTICALS & VIDEOS______________________________________*/
.section-content-va {
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 4em;
  padding: 0.5em;
}
.section-content-va-title {
  color: #b4b4b4;
  font-size: 2em;
  margin-bottom: -0.2em;
}
.section-content-va-sub {
  justify-self: center;
}

.section-content-links-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  padding: 1em;
  padding-top: 0;
}
.section-content-link {
  display: grid;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.section-content-link-devider {
  width: 1px;
  height: 55px;
  background-color: black;
  opacity: 0.5;
}
.va-img {
  height: 45px;
  width: auto;
  transition: height 0.8s;
}

.va-img-book {
  width: 250px;
  height: auto;
  padding-top: 1rem;
}

/* SECTION THREE ______________________________________*/
.section-three {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  text-align: center;
  padding: 0em 5em;
}
.section-three-intro {
  grid-column: 1/-1;
  margin: 3em 20vw;
  margin-bottom: 1em;
}
.title-medium-subtle {
  color: #b4b4b4;
  font-size: 2.5vw;
}
.quote {
  max-width: 24vw;
  font-size: 1em;
}

.quote-sign {
  font-family: "Courier New", Courier, monospace;
  color: #f39201;
  font-size: 2em;
  font-weight: 800;
}
.quote-text {
  font-weight: 300;
}

.quote-by {
  font-weight: 600;
  font-size: 1em;
}
.section-four {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1em 15vw;
}
.logo-div {
  height: auto;
  width: 150px;
  margin: 0.5em 0.5em;
}

@media screen and (max-width: 850px) {
  .quiz-results-points {
    height: 150px;
    width: 150px;
  }
  .quiz-results-points {
    font-size: 4em;
  }
}

@media screen and (max-width: 600px) {
  .meter-button-container {
    grid-row: 2/3;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    padding: 0.2em 0.1em;
  }
  .meter-button {
    margin-bottom: 0.2em;
    font-size: 0.8em;
    height: 34px;
  }
  .meter-button-active {
    font-size: 0.6em;
    width: 98%;
    height: 30px;
  }

  .quiz-content {
    grid-template-rows: auto auto;
    grid-template-columns: 50% 50%;
  }
  #quiz-button-back {
    grid-row: 2/-1;
    grid-column: 1/2;
  }
  #quiz-button-next {
    grid-row: 2/-1;
    grid-column: 2/-1;
  }
  .question-container {
    grid-column: 1/3;
    grid-row: 1/2;
    grid-template-rows: auto;
    padding-bottom: 0.1em;
  }
  .quiz-step-button {
    height: 50px;
    width: 50px;
    margin: 0 1em;
    margin-bottom: 1em;
  }
  .quiz-intro-container {
    padding: 0;
  }
  .quiz-intro-instructions {
    padding: 0 1em;
  }
  .quiz-intro-title {
    font-size: 1.2em;
  }
  .quiz-intro-button {
    width: 60%;
    height: 50px;
    margin: 1em 0;
  }
  .question-text {
    padding: 0.7em;
  }

  .quiz-reuslts-points {
    height: 100px;
    width: 100px;
    border-radius: 100px;
    font-size: 2.5em;
  }
  .quiz-results-points-max {
    font-size: 0.3em;
    margin-top: -5px;
  }
  .quiz-results-answers-container-show {
    height: auto;
  }
  .quiz-content-results {
    grid-template-columns: 100%;
    height: 20%;
    padding: 0;
  }
  .question-container-resutls {
    padding: 0 1em;
  }
  .quiz-results-container {
    grid-template-rows: auto auto auto;
  }
  .question-container-results {
    padding: 1em;
  }
  .quiz-results-text-container {
    grid-row: 2/3;
    grid-column: 1/3;
  }
  .quiz-results-point-container {
    grid-row: 1/2;
    grid-column: 1/3;
    padding-bottom: 1em;
  }
  .results-answer-container {
    grid-row: 3/4;
  }
  .quiz-results-points {
    width: 80px;
    height: 80px;
    border: 2px solid white;
    border-radius: 125px;
    font-size: 2em;
    font-weight: 600;
  }
  .quiz-results-title {
    justify-self: center;
  }
}

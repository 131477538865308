.test-container {
  max-width: 900px;
  /*box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);*/
  margin: 4em 0.2em;
  display: grid;
  color: white;
}

.meter-container {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  justify-items: center;
  align-items: end;
  z-index: 0;
}
.meter {
  margin: 10px;
  display: grid;
  justify-items: center;
}

.svg-container {
  padding: 1%;
  margin-bottom: -5%;
  transition: height 0.5s ease, width 0.5s ease;
}

.meter-button-container {
  grid-row: 2/3;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  align-items: center;
  z-index: 3;
}

.meter-progressbar-container {
  grid-row: 3/4;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}
.meter-progressbar {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: auto;
  justify-content: center;

  padding: 0 1em;
}
.meter-progressbar-bar {
  width: 15%;
  height: 0px;
  margin: 5px;
  transition: height 0.5s ease;
}
.meter-progressbar-bar-show {
  min-width: 80%;
  height: 8px;
  margin: 5px;
  transition: height 0.5s ease;
}
.meter-button {
  display: grid;
  justify-content: center;
  align-items: center;
  justify-self: center;
  width: 98%;
  height: 60px;
  border-radius: 30px;
  color: white;
  font-size: 1.1em;
  font-weight: 500;
  transition: all 0.5s ease;
}
.meter-button-active {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1em;
  /*width: 98%;
  height: 60px;*/
  width: 90%;
  height: 52px;
}

.quiz-content-container {
  display: grid;
}

.quiz-content {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  justify-content: center;
  grid-auto-flow: column;
}

.quiz-checkbox-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(5, 60px);
  justify-content: center;
}
.question-container {
  display: grid;
  padding: 20px;
  grid-template-rows: auto 100px auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.checkbox-container {
  display: grid;

  justify-content: center;
  align-items: center;
}

.checkbox {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #404040;
  display: grid;
  justify-content: center;
  align-items: center;
}
.checkbox-fill {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #404040;
}

.quiz-step-button {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  align-self: center;
  display: grid;
  justify-content: center;
  align-content: center;
}
.quiz-step-button-previous {
  justify-self: end;
}
.quiz-step-button-next {
  justify-self: start;
}
.question-title {
  font-weight: 700;
  color: white;
  font-size: 1.2em;
}
.question-text {
  font-size: 1.2em;
  padding: 0.5em;
}

.quiz-results-container {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-template-rows: auto;
  padding: 20px;
}
.quiz-results-text-container {
  display: grid;
  grid-template-rows: auto auto;
}
.quiz-results-title {
  font-weight: 600;
  margin-bottom: 10px;
}
.results-answer-container {
  grid-row: 2/-1;
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
}
.quiz-results-answers-container {
  height: 0px;
  opacity: 0;
  transition: height 1s 0.2s ease, opacity 0.5s ease;
  overflow: hidden;
}
.quiz-results-answers-container-show {
  height: auto;
  opacity: 1;
  transition: height 1s ease, opacity 0.5s 0.2s ease;
}
.quiz-results-answers-button {
  font-weight: 600;
  font-size: 0.8em;
  justify-self: center;
  margin-top: 10px;
  z-index: 2;
}
.quiz-results-point-container {
  justify-self: center;
  align-content: center;
  display: grid;
}

.quiz-results-points {
  display: grid;
  justify-content: center;
  align-content: center;
  width: 200px;
  height: 200px;
  border: 4px solid white;
  border-radius: 125px;
  font-size: 5.5em;
  font-weight: 600;
}
.quiz-results-points-tot {
  line-height: 1;
  justify-self: center;
}
.quiz-results-points-max {
  font-size: 0.2em;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  margin-top: -10px;
  justify-self: center;
}

.question-container-results {
  display: grid;
  padding: 0px 100px;

  justify-content: center;
  align-items: center;
  text-align: center;
}

.quiz-content-results {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  grid-auto-flow: column;
  padding: 20px;
}
.quiz-intro-container {
  display: grid;
  grid-template-rows: auto;
  color: #404040;
  justify-content: center;
  padding: 20px 100px;
  justify-items: center;
  text-align: center;
}
.quiz-intro-title {
  font-size: 1.5em;
  font-weight: 700;
  width: 80%;
}
.quiz-intro-instructions {
  font-size: 1em;
  font-weight: 400;
}
.quiz-intro-button {
  width: 300px;
  height: 60px;
  border-radius: 30px;
  background: #f39201;
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  display: grid;
  align-items: center;
  margin-top: 1em;
}
.emty {
}

.text-section {
  background-color: #af8cb6;
  color: white;
  padding: 10px;
  text-align: center;
  display: grid;
}
.start-button {
  height: 60px;
  width: 300px;
  background-color: #f39201;
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  border-radius: 30px;
  justify-self: center;
  display: grid;
  align-items: center;
  margin: 10px;
}
.instructions-container {
  background-color: white;
  color: black;
  display: grid;
  grid-auto-rows: auto;
  justify-content: center;
  align-items: center;
}

.back-button-container {
  grid-column: 1/2;
  justify-self: right;
}

.question-content-container {
  grid-column: 2/3;
}

.next-button-container {
  grid-column: 3/-1;
  justify-self: left;
}

.quiz-button {
  height: 60px;
  width: 60px;
  background-color: #8a5994;
  border-radius: 50%;
}

.question-content {
  display: grid;
  grid-template-rows: 1fr minmax(120px, auto) 1fr;
}

.question-options-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 20%;
  justify-content: center;
  align-items: center;
}

.question-option {
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
}

.check-box {
  background-color: white;
  border: 2px solid #8a5994;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  justify-self: center;
  display: grid;
  justify-content: center;
  align-items: center;
}

.check-box-active {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #8a5994;
}

.check-box-inactive {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: white;
}

.progressbar-container {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  column-gap: 8px;
  justify-content: center;
}

.progressbar-inactive {
  height: 10px;
  width: 40px;
  margin: 0px 2px;
  justify-self: center;
}

.p1-inactive {
  background-color: #af8cb6;
}

.p1-active {
  background-color: #8a5994;
}

.p2-inactive {
  background-color: #7f87a6;
}

.p2-active {
  background-color: #4a5681;
}
.p3-inactive {
  background-color: #6e7f89;
}

.p3-active {
  background-color: #304857;
}

.part-button {
  height: 60px;
  width: 300px;
  border-radius: 30px;
  color: white;
  background-color: #8a5994;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  font-weight: 500;
  margin: 5px 0px;
}

.partOne-button-color-inactive {
  background-color: #8a5994;
}
.partOne-button-color-active {
  background-color: #af8cb6;
  color: rgba(255, 255, 255, 1);
}

.partTwo-button-color-inactive {
  background-color: #4a5681;
}
.partTwo-button-color-active {
  background-color: #a4aac0;
  color: white;
}

.partThree-button-color-inactive {
  background-color: #304857;
}
.partThree-button-color-active {
  background-color: #97a3ab;
  color: white;
}

.results-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: repeat(2, auto);
}

.results-text-container {
  grid-row: 1/2;
  grid-column: 1/2;
}
.results-points-container {
  grid-row: 1/2;
  grid-column: 2/-1;
}
.results-my-answers {
  grid-column: 1/-1;
  grid-row: 2/-1;
  display: grid;
  padding: 20px;
  grid-template-rows: 1px auto;
  justify-self: center;
  justify-items: center;
}

.hide-result-answer-container {
  display: none;
}
.show-result-answer-container {
  display: inline-block;
}

.evaluation-mobile-view {
  display: none;
  justify-items: center;
  text-align: center;
  padding: 1em 2em;
}

.signup-container {
  position: fixed;
  width: 450px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.8);
  top: 120px;
  right: -450px;
  z-index: 7;
  padding: 20px;
  display: grid;
  grid-template-rows: 1fr, 1fr, 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
}

.close-popup {
  position: absolute;
  top: 0px;
  right: 10px;
  color: white;
  font-size: 1em;
  font-weight: 900;
  cursor: pointer;
}

.email-field {
  width: 90%;
  height: 40px;
  background: white;
  color: black;
  justify-self: center;
  align-content: center;
  display: grid;
}
.signup-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.input-field {
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;
  font-size: 1em;
  font-weight: 700;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.signup-button {
  width: 120px;
  height: 30px;
  border-radius: 20px;
  margin-top: 10px;
  font-size: 0.8em;
  display: grid;
  align-items: center;
  cursor: pointer;
}

.signup-primary {
  background-color: #f39201;
  justify-self: right;
}

.signup-secondary {
  border: 1px solid;
  border-color: #f39201;
  color: #f39201;
  justify-self: left;
}

.signup-title {
  font-size: 1.2em;
  font-weight: 800;
}

.singup-info {
  font-size: 0.8em;
}

.sendOut {
  right: -450px;
  background-color: rgba(0, 0, 0, 0);
}
.slideIn {
  animation-name: slideIn;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.slideOut {
  right: 40px;
  background-color: rgba(0, 0, 0, 0.8);
  animation-name: slideOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes slideIn {
  0% {
    background-color: rgba(0, 0, 0, 0);
    right: -450px;
  }
  100% {
    right: 40px;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

@keyframes slideOut {
  0% {
    right: 10px;
    background-color: rgba(0, 0, 0, 0.8);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
    right: -450px;
  }
}

@media screen and (max-width: 900px) {
  .signup-container {
    width: 300px;
    top: 80px;
  }

  @keyframes slideIn {
    0% {
      background-color: rgba(0, 0, 0, 0);
      right: -300px;
    }
    100% {
      right: 10px;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  @keyframes slideOut {
    0% {
      right: 10px;
      background-color: rgba(0, 0, 0, 0.8);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
      right: -300px;
    }
  }
}

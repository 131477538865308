.contact-section-one {
  background-color: darkslategrey;
  background-image: url("../images/contact-desktop-1920.jpg");
  background-size: cover;
  background-repeat: no-repeat center center fixed;
  background-position: center;
  background-position: 70% 50%;
  height: 100vh;
  margin: 0;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  animation-name: slimDownContact;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes slimDownContact {
  0% {
    height: 120vh;
  }
  100% {
    height: calc(100vh);
  }
}

.contact-border {
  padding: 3em 5em;
  background-color: rgba(48, 72, 87, 1);
  display: grid;
}

.contact-name {
  font-size: 3vw;
  font-weight: 600;
  color: #fff;
  text-align: center;
  line-height: 100%;
}

.contact-email {
  font-size: 3vw;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 100%;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  transition: color 1s ease;
}
.contact-email:hover {
  color: rgba(243, 146, 1, 1);
}
.contact-icon-container > img {
  height: 2em;
  margin: 0.2em;
  cursor: pointer;
}
.contact-icon-container {
  justify-content: center;
  justify-items: center;
  justify-self: center;
  display: grid;
  width: max-content;
  grid-auto-flow: column;
  margin-top: 1em;
  cursor: pointer;
}

a:link {
  color: white;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: white;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: white;
  text-decoration: none;
}

/* selected link */
a:active {
  color: white;
  text-decoration: none;
}

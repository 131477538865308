.footer {
  background-color: rgba(48, 72, 87, 1);
  height: auto;
  color: white;
  display: grid;
  padding: 2em 0.5em;
  grid-template-columns: 1fr 2px 1fr;
  column-gap: 1em;
  padding-bottom: 1em;
}
.footer-border {
  align-self: center;
  height: 100%;
  background-color: white;
  width: 1px;
}
.footer-col-one {
  display: grid;
  justify-content: end;
}
.footer-col-two {
  display: grid;
  justify-content: start;
}

.footer-bold {
  font-weight: 600;
}

.footer-icon-container > img {
  height: 2em;
  margin: 0.2em;
  cursor: pointer;
}
.footer-icon-container {
  grid-column: 1/-1;
  grid-row: 2/3;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  display: grid;
  width: max-content;
  grid-auto-flow: column;
  margin-top: 1em;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .footer {
    font-size: 0.7em;
  }
}

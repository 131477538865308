.change-text-container {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  height: 50%;
  width: 50%;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.hidden {
  display: none;
}

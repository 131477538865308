.navbar-container-default {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  margin: 0;
  background-color: rgba(48, 72, 87, 0);
  transition: background-color 100ms linear;
  display: grid;
  grid-template-columns: 20vw auto max-content;
  grid-template-rows: 60px auto;
  align-items: center;
  z-index: 10;
}

.navbar-language-container {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-default {
  grid-column: 3/-1;
  font-size: 0.9em;
}
.navbar-scrolled {
  background-color: rgba(48, 72, 87, 1);
}
.navbar-default ul {
  color: white;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-end;
  margin-right: 2em;
  margin-top: 0;
  margin-bottom: 0;
}

.navbar-default ul li {
  display: block;
  margin: 1em;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}
.navbar-language-line {
  height: 20px;
  width: 1px;
  background-color: white;
  align-self: center;
}
.navbar-language-text {
  font-weight: 300;
  font-size: 0.8em;
}

#navbar-language-se {
  margin-right: 0.2em;
}
.navbar-language-selected {
  color: #f39201;
  font-weight: 600;
}

.inactive-link {
  color: #fff !important;
  text-decoration: none;
}
.inactive-link:hover {
  color: #f39201 !important;
}
.active-link {
  color: #f39201 !important;
}

#menu-button-div {
  width: 42px;
  height: 48px;
  display: none;
}
.navbar-logo {
  grid-column: 1/2;
  grid-row: 1/2;
  height: 1.5em;
  align-self: center;
  justify-self: start;
}

#navbar-logo {
  height: 100%;
  width: auto;
  margin-left: 2em;
}

.menu-bar {
  height: 4px;
  border-radius: 2px;
  width: 40px;
  background-color: white;
  transition: all 0.2s ease;
}

#menu-bar-one {
}
#menu-bar-two {
}
#menu-bar-three {
}

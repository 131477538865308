.contenttwo-container {
  padding: 2em;
  text-align: left;
  background-color: #dadde5;
}
.content-main-title {
  font-size: 1.5em;
  font-weight: 600;
}

.content-sub-title {
  font-size: 1em;
  font-weight: 600;
  margin-top: 0.2em;
}

.content-running-text {
  font-size: 1em;
  font-weight: 300;
}
.content-bold {
  font-weight: 600;
}

.content-quote {
  font-style: italic;
  font-weight: 600;
}

.content-quote-auth {
  font-size: 0.9em;
}

.content-sub-information {
  font-size: 0.95em;
  font-weight: 600;
}

.content-list-extra {
  font-size: 0.8em;
  margin-bottom: 0.1em;
}

.button-download-two {
  display: grid;
  justify-items: end;
  align-items: center;
  grid-template-columns: 75% 25%;
  width: 120px;
  height: 30px;
  border-radius: 50px;
  font-size: 0.8em;
  background-color: #f39201;
  color: white;
  margin-top: 0.5em;
  font-weight: 500;
}
.button-download-two:hover {
  background-color: #c17400;
}

.svg-download-two {
  justify-self: start;
  padding-left: 4px;
  margin-left: 4px;
}

.reviews-container {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 80%;
  justify-self: center;
  margin-bottom: 1.2em;
  max-width: 900px;
}

.reviews-carousel-container {
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  margin-top: 3em;
}

.review-title-text {
  color: #b4b4b4;
  font-size: 2em;
}

.dot-container {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  height: 18px;
}

.section-three-point-five {
  display: grid;
}

.review-content-container {
  opacity: 0;
  padding: 10px;
  display: grid;
  justify-items: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.review-arrow {
  font-size: 3em;
  color: white;
  font-weight: 100;
  cursor: pointer;
  transition: color 1s;
}
.review-arrow:hover {
  color: #f39201;
}

.review-text {
  font-weight: 300;
  font-size: 1.3em;
  margin-bottom: 10px;
}
.review-name {
  font-weight: 600;
  font-size: 1.2em;
}
.review-title {
  font-weight: 500;
  font-size: 0.9em;
}
.review-quote-sign {
  color: #f39201;
  font-weight: 700;
  font-size: 1.2em;
  opacity: 0.5;
  font-family: "Courier New", Courier, monospace;
}

.review-timer-contatiner {
  width: 300px;
  height: 2px;
  background-color: #f3920130;
  margin-bottom: 0.8em;
  margin-top: 10px;
  position: relative;
}
.review-timer-bar {
  width: 100%;
  height: 2px;
  background-color: #f39201;
  animation-name: timer-bar;
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-play-state: running;
  animation-fill-mode: forwards;
}
.timer-pause-sign {
  position: absolute;

  font-weight: 700;
  color: #f39201;
  font-size: 10px;
  opacity: 0;
  transition: opacity 1s;
  background-color: white;
  width: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.timer-pause-sign-show {
  opacity: 1;
}
.pause-timer {
  animation-play-state: paused;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: white;
  margin: 2.5px;
  border: solid 1px;
  border-color: #f39201;
  border-radius: 6px;
  transition: all 0.5s;
  cursor: pointer;
}

.dot-active {
  width: 8px;
  height: 8px;
  background-color: #f39201;
}

.toCurrrentAnimation {
  animation-name: review-appear;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

@keyframes review-appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes timer-bar {
  0% {
    width: 100%;
    opacity: 1;
  }
  100% {
    width: 0%;
    opacity: 1;
  }
}

@media screen and (max-width: 900px) {
  .review-title-text {
    margin: 2em 2vw;
    margin-bottom: 0em;
    font-size: 1.5em;
  }
  .review-text {
    font-size: 1.2em;
  }
  .reviews-container {
    width: 95%;
  }
  .reviews-carousel-container {
    margin-top: 1em;
  }
}

.contentone-container {
  padding: 2em;
  text-align: left;
  background-color: #d5dadd;
  color: black;
}
.content-main-title {
  font-size: 1.5em;
  font-weight: 600;
}

.content-sub-title {
  font-size: 1em;
  font-weight: 600;
  margin-top: 0.2em;
}

.content-running-text {
  font-size: 1em;
  font-weight: 300;
}
.content-bold {
  font-weight: 600;
}

.button-download {
  display: grid;
  justify-items: end;
  align-items: center;
  grid-template-columns: 80% 20%;
  width: 180px;
  height: 40px;
  border-radius: 50px;
  font-size: 1em;
  background-color: #f39201;
  color: white;
  margin-top: 0.5em;
  font-weight: 500;
}
.button-download:hover {
  background-color: #c17400;
}

#svg-download {
  justify-self: start;
  padding-left: 4px;
  margin-left: 4px;
}

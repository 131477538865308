#speed {
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 40;
  stroke-dasharray: 660;
  stroke-dashoffset: 0;
  transform-origin: 160px 160px;
  transform: rotate(-225deg);
}

#speedFill {
  fill: none;
  stroke: #869cc6;
  stroke-width: 40;
  stroke-dasharray: 880;
  stroke-dashoffset: 880;
  transform-origin: 160px 160px;
  transform: rotate(-225deg);
  transition: all 1s;
}

#speedFillOne {
  fill: none;
  stroke: #869cc6;
  stroke-width: 40;
  stroke-dasharray: 880;
  stroke-dashoffset: 880;
  transform-origin: 160px 160px;
  transform: rotate(-225deg);
  transition: all 1.5s ease-out;
}

#needleOne {
  transform-origin: 150px 150px;
  transform: rotate(225deg);
  stroke: none;
  fill: #4a5681;
  transition: all 1.5s ease-out;
}

#speedFillTwo {
  fill: none;
  stroke: #869cc6;
  stroke-width: 40;
  stroke-dasharray: 880;
  stroke-dashoffset: 880;
  transform-origin: 160px 160px;
  transform: rotate(-225deg);
  transition: all 1.5s ease-out;
}

#needleTwo {
  transform-origin: 150px 150px;
  transform: rotate(225deg);
  stroke: none;
  fill: #4a5681;
  transition: all 1.5s ease-out;
}

#speedFillThree {
  fill: none;
  stroke-width: 40;
  stroke-dasharray: 880;
  stroke-dashoffset: 880;
  transform-origin: 160px 160px;
  transform: rotate(-225deg);
  transition: all 1.5s ease-out;
}
#needleThree {
  transform-origin: 150px 150px;
  transform: rotate(225deg);
  stroke: none;
  fill: #4a5681;
  transition: all 1.5s ease-out;
}

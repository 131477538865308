.about-section-one {
  background-color: darkslategrey;
  background-image: url("../images/about-desktop-1920.jpg");
  background-size: cover;
  background-repeat: no-repeat center center fixed;
  background-position: 50% 40%;
  height: 100vh;
  margin: 0;
  padding: 20px;
  display: grid;
  justify-content: center;
  align-items: flex-end;
  animation-name: slimDownAbout;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes slimDownAbout {
  0% {
    height: 80vh;
  }
  100% {
    height: 40vh;
  }
}

.about-section-one-intro {
  font-size: 4vw;
  font-weight: 700;
  margin-bottom: 2em;
  color: #fff;
  animation-name: moveIntroDown;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-duration: 2s;
}

@keyframes moveIntroDown {
  0% {
    margin-bottom: 2em;
  }
  100% {
    margin-bottom: 0em;
  }
}

.about-first-word {
  color: #f39201;
}

.about-section-two {
  display: grid;
  grid-auto-flow: row;
  padding: 2em 4em;
  justify-items: center;
  text-align: center;
}

.about-section-two-intro {
  grid-column: 1/-1;
  padding: 3em;
  padding-bottom: 1.5em;
  justify-self: center;
}

.about-section-two-text {
  padding: 0 1em;
  text-align: left;

  align-self: center;
  max-width: 765px;
  justify-self: center;
}

.about-title-medium {
  font-size: 2.5em;
  font-weight: bolder;
  max-width: 900px;
}
.about-section-two-bold {
  font-weight: bold;
}
.about-section-two-book-link {
  font-weight: bold;
  font-style: italic;
  color: #f39201;
  cursor: pointer;
}
.section-two-merits {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.about-section-two-bio {
  display: grid;
  grid-auto-flow: column;
}
.about-section-two-about-text {
  padding: 0 1em;
  text-align: left;

  align-self: center;
  max-width: 765px;
  justify-self: center;
}
.profile-picture-container {
  display: grid;
  grid-column: 1/2;
  justify-items: center;
  padding-bottom: 1em;
}
.about-profile-picture {
  display: grid;
  height: 300px;
  width: auto;
}

.about-border {
  width: 200px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  justify-self: center;
  margin: 2em 0;
  margin-top: 1em;
}
.about-content-main-title {
  font-size: 1.5em;
  font-weight: 600;
}
.about-content-sub-title {
  font-size: 1em;
  font-weight: 600;
  margin-top: 0.2em;
}

.about-running-text {
  font-size: 1em;
  font-weight: 300;
}
.about-bold {
  font-weight: 600;
}

.about-max765 {
  max-width: 765px;
}

.about-book {
  max-width: 765px;
  text-align: left;
  display: grid;
  justify-content: left;
  width: 100%;
}
.about-cursive {
  font-style: italic;
}

@media screen and (max-width: 600px) {
  .section-two-merits {
    grid-template-columns: 1fr;
  }
  .section-two-col-one {
    margin-bottom: 0;
  }
  .section-two-col-two {
    margin-top: 0;
  }
}

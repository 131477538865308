.book-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-items: center;
  justify-content: center;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  color: #404040;
}

.book-content {
  height: 80vh;
  max-width: 765px;
  padding: 2em 4em;
  justify-items: center;
  text-align: center;
  background-color: white;
  text-align: left;
  overflow-y: scroll;
  position: relative;
}

.book-container-hide {
  display: none;
}

.book-title {
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
}

.book-sub-title {
  font-size: 1em;
  font-weight: 300;
  text-align: center;
}

.book-text-bold {
  font-weight: 600;
}
.book-text-italic {
  font-style: italic;
}

.book-close-button {
  text-decoration: underline;
  font-weight: 400;
  font-size: 0.8rem;
  cursor: pointer;
  grid-row: 4/5;
  margin-top: 0.5rem;
  color: white;
}

@media screen and (max-width: 900px) {
  .book-content {
    padding: 0 1em;
  }
}

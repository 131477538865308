.signup-container-brainz {
  position: fixed;
  width: 300px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.8);
  top: 120px;
  right: -450px;
  z-index: 7;
  padding: 20px;
  display: grid;
  grid-template-rows: 1fr, 1fr, 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
}

.close-popup-brainz {
  position: absolute;
  top: 0px;
  right: 10px;
  color: white;
  font-size: 1em;
  font-weight: 900;
  cursor: pointer;
}

.email-field-brainz {
  width: 90%;
  height: 40px;
  background: white;
  color: black;
  justify-self: center;
  align-content: center;
  display: grid;
}
.signup-button-container-brainz {
  display: grid;
  grid-template-rows: auto;
  column-gap: 20px;
}

.input-field-brainz {
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;
  font-size: 1em;
  font-weight: 700;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.signup-button-brainz {
  padding: 0.2rem 1rem;
  border-radius: 20px;
  margin-top: 10px;
  font-size: 0.8em;
  display: grid;
  align-items: center;
  cursor: pointer;
}

.signup-primary-brainz {
  background-color: #f39201;
  justify-self: center;
}

.signup-secondary-brainz {
  border: 1px solid;
  border-color: #f39201;
  color: #f39201;
  justify-self: center;
}

.signup-title-brainz {
  font-size: 1.2em;
  font-weight: 800;
}

.cover-image {
  height: 300px;
  margin-top: 1em;
}
.video-image {
  width: 90%;
  margin-top: 1em;
  cursor: pointer;
}

.singup-info-brainz {
  font-size: 0.8em;
}

.sendOut-brainz {
  right: -450px;
  background-color: rgba(0, 0, 0, 0);
}
.slideIn-brainz {
  animation-name: slideIn;
  animation-duration: 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.slideOut-brainz {
  right: 40px;
  background-color: rgba(0, 0, 0, 0.8);
  animation-name: slideOut;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.signup-container-review-fullscreen{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
}

.signup-review-container{
  max-width: 500px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  background-color: #f39201;
  margin: 1em;
}

.signup-container-video-fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: 5% 80% auto;
  flex-direction: column;
  z-index: 10;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
}
@media screen and (max-width: 500px){
  .signup-container-video-fullscreen{
    grid-template-rows: 5% 60% auto;
  }
}

.signup-container-video-fullscreen > iframe {
  grid-row: 2/3;
  grid-column: 1/5;
  z-index: 2;
}
.loading-container-video {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-row: 2/3;
  grid-column: 1/5;
  height: auto;
}
.hide-video {
  display: hidden;
}

.video-button-container {
  grid-column: 1/5;
  grid-row: -2/-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}

.review-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
}

@media screen and (max-width: 500px){
.signup-review-text{
font-size: 0.8rem;
}
}

.signup-review-container > p, h5 {
  margin: auto;
}

.signup-review-arrow{
  font-size: 1.3em;
  opacity: 0.3;
}

.signup-review-arrow-left{
  padding-right: 0.8rem;
}
.signup-review-arrow-right{
  padding-left: 0.8rem;
}


.review-dots-container{
  display: flex;
  flex-direction: row;
  
}

.signup-review-text-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.review-dot{
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 100rem;
  margin: 0px 2px;
} 
.review-dot-active{
  background-color: white;
}

.video-button-pdf {
  grid-row: 4/5;
  align-self: center;
  width: max-content;
  padding: 0.5rem 1.5rem;
  background-color: #f39201;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  z-index: 11;
}

.video-button-pdf:hover{
  background: #df8703;
}

.video-close-text {
  text-decoration: underline;
  font-weight: 400;
  font-size: 0.8rem;
  cursor: pointer;
  grid-row: 4/5;
  margin-top: 0.5rem;
}

@keyframes slideIn {
  0% {
    background-color: rgba(0, 0, 0, 0);
    right: -450px;
  }
  100% {
    right: 40px;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

@keyframes slideOut {
  0% {
    right: 10px;
    background-color: rgba(0, 0, 0, 0.8);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
    right: -450px;
  }
}

@media screen and (max-width: 900px) {
  .signup-container-brainz {
    width: 200px;
    top: 80px;
  }
  .cover-image {
    height: 200px;
  }
  .signup-title-brainz {
    font-size: 0.8em;
  }

  @keyframes slideIn {
    0% {
      background-color: rgba(0, 0, 0, 0);
      right: -300px;
    }
    100% {
      right: 10px;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  @keyframes slideOut {
    0% {
      right: 10px;
      background-color: rgba(0, 0, 0, 0.8);
    }
    100% {
      background-color: rgba(0, 0, 0, 0);
      right: -300px;
    }
  }
}

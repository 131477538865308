.admin-page-container {
  height: auto;
  padding: 2em;
  display: grid;
}

.admin-sign-in-fields {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.admin-sign-in-form {
  display: grid;
  justify-content: center;
  justify-items: center;
}

.admin-sign-in-form-field {
  font-size: 1em;
  border-radius: 50px;
  border: 1px solid #f39201;
  padding: 0.2em 1em;
  margin-bottom: 0.2em;
  text-align: center;
}

.admin-sign-in-form-button {
  width: max-content;
  margin-top: 0.5em;
  justify-self: center;
  font-size: 1em;
  background-color: #f39201;
  color: white;
  padding: 0.2em 1.4em;
  font-weight: 500;
  border-radius: 50px;
  border: none;
  cursor: pointer;
}

.admin-sign-in-form-button-forgot {
  color: #f39201;
  background-color: white;
  font-size: 0.8em;
  border: none;
  margin-top: 0.2em;
  text-decoration: underline;
  cursor: pointer;
}

.admin-sign-in-logo {
  width: 150px;
  margin-bottom: 0.3em;
}

/* When signed in*/

.admin-all-forms-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2em;
  text-align: center;
  justify-content: center;
}
.admin-a-form {
  width: 300px;
  border: 1px solid black;
  padding: 1em;
  margin: 0.5em;
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-template-rows: auto auto 30px;
  position: relative;
}
.admin-form-language {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0em;
  margin-right: 0.5em;
}

.admin-a-form-title {
  font-size: 1em;
  font-weight: 600;
}

.admin-a-form-link {
  color: #0400d3;
  font-size: 0.5em;
}

.admin-edit-form-button {
  background-color: white;
  color: #f39201;
  border: 1px solid #f39201;
  border-radius: 50px;
  width: max-content;
  height: 25px;
  padding: 0.3em 1em;
  margin: 0.2em;
  cursor: pointer;
}
.admin-edit-form-button:hover {
  color: white;
  background-color: #f39201;
}
.admin-new-form-button {
  width: max-content;
  padding: 0.5em 1em;
  background-color: #f39201;
  color: white;
  border-radius: 50px;
  border: none;
  margin: 0.3em;
  font-weight: 600;
  font-size: 1em;
  justify-self: center;
  cursor: pointer;
}
.admin-new-form-button:hover {
  background-color: #d17e02;
}
.admin-from-sign-out {
  color: #f39201;
  background-color: white;
  font-size: 0.8em;
  border: none;
  margin-top: 0.2em;
  text-decoration: underline;
  cursor: pointer;
}
.admin-from-sign-out:hover {
  color: #f34e01;
}

.admin-new-form-id-field {
  width: 350px;
  padding: 0.5em 1em;
  justify-self: center;
  border: 1px solid #f39201;
  background-color: white;
  border-radius: 50px;
  font-size: 1.2em;
  text-align: center;
}

#newIDtext {
  justify-self: center;
  color: red;
  font-size: 0.8em;
}
.red-text {
  color: red;
}

.form-container {
  background-color: #f5f5f5;
  display: grid;
  justify-content: center;
  align-content: center;
  padding: 2em;
  min-height: 100vh;
}

.form-page {
  width: 900px;
  padding: 1em;
  background-color: #6e7f89;
  border: 1em solid white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  display: grid;
  grid-template-rows: auto;
  color: white;
  justify-items: center;
}
.logo-container {
  display: grid;
  justify-content: center;
  align-content: center;
  padding: 1em 0;
}

.logo-container > img {
  width: 200px;
  height: auto;
}

.title-container {
  background-color: #304857;
  width: calc(100% + 2em);
  justify-self: center;
  font-size: 2em;
  padding: 1em 2em;
  text-align: center;
  font-weight: 500;
  font-family: "Ultra", serif;
}

.form-instructions-container {
  display: grid;
  max-width: 80%;
  font-size: 0.9em;
  text-align: center;
  padding: 0.5em 0;
}
.form-all-questions-container {
  display: grid;
  text-align: center;
}
.form-question-container {
  display: grid;
  justify-content: center;
  margin: 1em 0;
}
.form-question-title {
  font-size: 1.2em;
  font-weight: bold;
}
.form-question-text {
  font-size: 1em;
  width: 80%;
  justify-self: center;
}
.form-meter-container {
  color: black;
  width: calc(100% + 3em);
  background-color: white;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  display: grid;
  justify-content: center;
  padding: 1em;
}
.form-meter-size {
  width: 300px;
}
/* A4 ratio: 1,41428571 */

.form-checkbox-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  width: 400px;
  justify-self: center;
  margin: 1em 0;
}

.form-checkbox {
  display: grid;
  justify-content: center;
}

.form-circle {
  width: 20px;
  height: 20px;
  border: 1px solid #304857;
  border-radius: 100px;
  background-color: white;
  display: grid;
  align-items: center;
  justify-content: center;
  justify-self: center;
}
.form-checked {
  width: 14px;
  height: 14px;
  border-radius: 100px;
  background-color: #304857;
  justify-self: center;
  align-self: center;
}

.form-unchecked {
  background-color: white;
  justify-self: center;
  align-self: center;
}

.form-print-button {
  width: 200px;
  height: 40px;
  border-radius: 100px;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #f39201;
  color: white;
  margin: 2em 0;
  justify-self: center;
}

.form-textarea {
  width: 100%;
  display: block;
  font-size: 1em;
  font-family: "Poppins", sans-serif;
  border: 1px solid white;
  padding: 0.5em;
  resize: vertical;
}
.form-textarea:focus {
  border-color: #304857;
}

.form-answers {
  width: 800px;
}
img#logo-black {
  display: none;
}
.form-checkbox {
  font-size: 0.8em;
}

.form-print-button {
  height: 50px;
  min-width: 200px;
  background-color: #f39201;
  border-radius: 35px;
  color: white;
  margin: 0.8em 0.5em;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 1em;
}
.form-devider {
  width: 400px;
  height: 1px;
  background-color: white;
  opacity: 0.3;
  justify-self: center;
}
/*  Mobileview */

@media screen and (max-width: 900px) {
  .form-page {
    width: 98vw;
    padding: 0;
  }
  .form-checkbox-container {
    width: 400px;
  }
  .title-container {
    padding: 1em 0.5em;
    margin-left: -0.5em;
    width: calc(100% + 1em);
    font-size: 1em;
    font-weight: 500;
  }
  .form-answers {
    width: 500px;
  }
  .form-devider {
    width: 300px;
  }
}

@media screen and (max-width: 550px) {
  .form-answers {
    width: 300px;
  }
  .form-devider {
    width: 200px;
  }
  .form-meter-container {
    width: calc(100% + 1em);
  }
  .form-print-button {
    display: none;
  }
  .form-checkbox-container {
    width: 80vw;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  .form-page * {
    visibility: visible;
    color: black;
  }
  .form-page {
    position: absolute;
    left: 0;
    top: 0;
  }
  .title-container {
    font-weight: 500;
    background-color: white;
    -webkit-print-color-adjust: exact;
    color: black;
    padding: 0em !important;
  }
  .form-checked {
    background-color: #6e7f89;
    -webkit-print-color-adjust: exact;
  }
  .form-unchecked {
    opacity: 0 !important;
  }

  .form-instructions-container {
    max-width: 95%;
    font-size: 0.8em;
  }
  .title-container {
    padding: 0.2em;
  }
  .form-meter-container {
    box-shadow: none;
  }
  img#logo-white {
    display: none;
  }
  img#logo-black {
    display: inline-block;
  }
  .form-print-button {
    display: none;
  }
  .form-devider {
    background-color: black;
    opacity: 0.1;
    -webkit-print-color-adjust: exact;
  }
}
